import GatsbyImage from 'gatsby-image';
import styled, { keyframes } from 'styled-components';

import globeImgCard from 'assets/images/pages/careers/mission/careers-mission-globe-card.png';
import globeImgSphere from 'assets/images/pages/careers/mission/careers-mission-globe-sphere.png';
import { media } from 'components/_settings/variables';

const AnimationBounce = keyframes`
0%   { transform: translateY(0); }
50%  { transform: translateY(-30px); }
100% { transform: translateY(0); }
`;

export const Image = styled(GatsbyImage)`
  display: block;
  max-width: 540px;
  width: 100%;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  ${media.sm} {
    max-width: 400px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: block;
  max-width: 540px;
  width: 100%;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  height: 590px;
  ${media.sm} {
    max-width: 400px;
    height: 450px;
  }
  ${media.xs} {
    height: 300px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 238px;
    background: linear-gradient(0deg, rgba(24, 110, 255, 0), #fff 0, hsla(0, 0%, 100%, 0.2) 97%);
    ${media.sm} {
      height: 160px;
    }
    ${media.xs} {
      height: 80px;
    }
  }
`;

export const CardsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const CardsItem = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 3px 5px 0 rgba(93, 123, 216, 0.4);
    border-radius: 3px;
    animation: ${AnimationBounce} 3s ease infinite;
  }

  &:nth-child(1) {
    &::before {
      top: 54px;
      left: 85px;
      width: 37px;
      height: 26px;
      transform: rotate(-6deg);
      background-image: url(${globeImgCard});
      animation-delay: 5s;

      ${media.sm} {
        left: 60px;
      }

      ${media.xs} {
        left: 25px;
      }
    }
  }

  &:nth-child(2) {
    &::before {
      top: 269px;
      left: 50px;
      width: 96px;
      height: 66px;
      transform: rotate(-13deg);
      background-image: url(${globeImgCard});
      animation-delay: 3.5s;

      ${media.sm} {
        top: 195px;
        left: 43px;
      }

      ${media.xs} {
        top: 145px;
      }
    }
  }

  &:nth-child(3) {
    &::before {
      top: 100px;
      right: 20px;
      width: 50px;
      height: 35px;
      transform: rotate(-6deg);
      background-image: url(${globeImgCard});
      animation-delay: 4s;

      ${media.xs} {
        top: 90px;
        right: 10px;
      }
    }
  }

  &:nth-child(4) {
    &::before {
      top: -4px;
      left: 50%;
      margin-left: -50px;
      width: 100px;
      height: 100px;
      background-image: url(${globeImgSphere});
      border-radius: 100%;
      box-shadow: 0 20px 15px -5px rgba(93, 123, 216, 0.2);
    }
  }
`;
