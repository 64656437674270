import * as React from 'react';

import { Container } from 'components/shared/container';
import { getTextWithoutParagraph } from 'utils';

import { Wrapper, StyledTitle, Description } from './careers-mission.styles';
import { Globe } from './globe';

export const Mission = ({ title, description, globeDescription }) => (
  <Wrapper>
    <Container>
      <StyledTitle as="h2" size="xl">
        {title}
      </StyledTitle>
      <Description dangerouslySetInnerHTML={{ __html: getTextWithoutParagraph(description) }} />
      <Globe description={globeDescription} />
    </Container>
  </Wrapper>
);
