import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  flex-basis: 520px;
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;

  ${media.lg} {
    flex-basis: 460px;
  }

  ${media.md} {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DescriptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;

  ${media.md} {
    display: block;
  }

  ${media.sm} {
    margin-bottom: 50px;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 40px;

  ${media.sm} {
    margin-bottom: 15px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background-color: #fbfbfe;
  padding-top: 330px;
  padding-bottom: 120px;

  @media only screen and (min-width: 1920.98px) {
    padding-top: 80px;
  }

  ${media.lg} {
    padding-top: 235px;
  }

  ${media.sm} {
    padding-top: 180px;
    padding-bottom: 20px;
  }
`;
