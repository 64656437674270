import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import rectangleImg from 'assets/images/pages/careers/commitment/rectangle@2x.png';
import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 85px;

  ${media.sm} {
    padding-bottom: 35px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -305px;
    right: 0;
    width: 100%;
    height: 100%;
    min-height: 1038px;
    background-image: url(${rectangleImg});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;

    ${media.sm} {
      min-height: 1116px;
    }
  }
`;

export const Container = styled(SharedContainer)``;

export const Title = styled.h2`
  font-size: 42px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  margin: 0 0 49px;
  color: ${color.text.primary};
  max-width: 410px;

  ${media.sm} {
    margin: 0 0 21px;
  }
`;

export const Description = styled.div`
  font-size: ${fontSize.sm};
  font-weight: 400;
  line-height: 1.75;
  color: ${color.text.secondary};
  margin: 0 0 40px;

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  column-gap: 160px;
  z-index: 1;

  ${media.lg} {
    column-gap: 80px;
  }

  ${media.md} {
    grid-template-columns: 12fr;
  }
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 592px;

  ${media.lg} {
    max-width: 500px;
  }
`;

export const ContentRight = styled.div`
  ${media.md} {
    grid-row-start: 1;
    margin-bottom: 45px;
  }
`;

export const Illustration = styled(GatsbyImage)`
  left: 9px;
  width: 351px;

  ${media.sm} {
    height: 280px;
    width: 100%;
    border-radius: 26.9px;
    overflow: hidden;
    left: 0;
  }
`;
