import * as React from 'react';

import {
  Wrapper,
  Illustration,
  SwitchersWrapper,
  Switcher,
  SwitcherTitle,
  SwitcherDescription,
} from './careers-values-tabs.styles';

export const TabsView = ({ items, activeItemIndex, onItemClick, illustrations }) => (
  <Wrapper>
    {illustrations.map((illustration, index) => (
      <Illustration
        src={illustration}
        alt=""
        visible={activeItemIndex === index}
        key={illustration}
      />
    ))}

    <SwitchersWrapper>
      {items.map(({ title, description }, index) => (
        <Switcher active={activeItemIndex === index} key={title} onClick={() => onItemClick(index)}>
          <SwitcherTitle as="h4" size="sm">
            {title}
          </SwitcherTitle>
          <SwitcherDescription>{description}</SwitcherDescription>
        </Switcher>
      ))}
    </SwitchersWrapper>
  </Wrapper>
);
