import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { TabsView } from './careers-values-tabs.view';

export const Tabs = ({ items }) => {
  const { illustration1, illustration2, illustration3, illustration4, illustration5 } =
    useStaticQuery(graphql`
      query {
        illustration1: file(
          relativePath: { eq: "pages/careers/values/careers-values-tabs-illustration1.svg" }
        ) {
          publicURL
        }
        illustration2: file(
          relativePath: { eq: "pages/careers/values/careers-values-tabs-illustration2.svg" }
        ) {
          publicURL
        }
        illustration3: file(
          relativePath: { eq: "pages/careers/values/careers-values-tabs-illustration3.svg" }
        ) {
          publicURL
        }
        illustration4: file(
          relativePath: { eq: "pages/careers/values/careers-values-tabs-illustration4.svg" }
        ) {
          publicURL
        }
        illustration5: file(
          relativePath: { eq: "pages/careers/values/careers-values-tabs-illustration5.svg" }
        ) {
          publicURL
        }
      }
    `);

  const [activeItemIndex, setActiveItemIndex] = React.useState(0);

  const illustrations = [
    illustration1.publicURL,
    illustration2.publicURL,
    illustration3.publicURL,
    illustration4.publicURL,
    illustration5.publicURL,
  ];

  return (
    <TabsView
      items={items}
      activeItemIndex={activeItemIndex}
      illustrations={illustrations}
      onItemClick={setActiveItemIndex}
    />
  );
};
