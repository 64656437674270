import * as React from 'react';

import { Container } from 'components/shared/container';

import { Wrapper, StyledTitle, DescriptionsWrapper, Description } from './careers-values.styles';
import { Stripes } from './stripes';
import { Tabs } from './tabs';

export const Values = ({ title, descriptions, tabs }) => (
  <Wrapper>
    <Container>
      <StyledTitle as="h2" size="xxl">
        {title}
      </StyledTitle>
      <DescriptionsWrapper>
        {descriptions.map((description) => (
          <Description key={description}>{description}</Description>
        ))}
      </DescriptionsWrapper>
      <Tabs items={tabs} />
    </Container>

    <Stripes />
  </Wrapper>
);
