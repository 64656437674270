import * as React from 'react';

import {
  Wrapper,
  IconWrapper,
  IconImage,
  TitleWrapper,
  StyledTitle,
  StyledExternalLinkIcon,
  Description,
} from './careers-team-link-block.styles';

export const LinkBlock = ({ title, description, url, icon, theme }) => (
  <Wrapper to={url} theme={theme}>
    <IconWrapper>
      <IconImage src={icon} width={45} height={45} loading="lazy" alt="" />
    </IconWrapper>
    <div>
      <TitleWrapper>
        <StyledTitle as="h3" size="sm">
          {title}
        </StyledTitle>
        <StyledExternalLinkIcon />
      </TitleWrapper>
      <Description>{description}</Description>
    </div>
  </Wrapper>
);
