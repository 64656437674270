import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { Triangle1View } from './careers-intro-triangle1.view';

export const Triangle1 = () => {
  const { stripes } = useStaticQuery(graphql`
    query {
      stripes: file(relativePath: { eq: "pages/careers/intro/careers-intro-triangle1.svg" }) {
        publicURL
      }
    }
  `);

  return <Triangle1View imageSrc={stripes.publicURL} />;
};
