import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { StripesView } from './careers-values-stripes.view';

export const Stripes = () => {
  const { stripes } = useStaticQuery(graphql`
    query {
      stripes: file(relativePath: { eq: "pages/careers/team/careers-values-stripes.svg" }) {
        publicURL
      }
    }
  `);

  return <StripesView imageSrc={stripes.publicURL} />;
};
