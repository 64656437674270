import styled from 'styled-components';

import { fontSize, color, transition, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Link } from 'components/shared/link';
import { Title } from 'components/shared/title';

export const StyledTitle = styled(Title)`
  margin-bottom: 65px;

  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Inner = styled(Container)``;

export const Wrapper = styled.section`
  padding-top: 350px;
  padding-bottom: 150px;

  @media only screen and (min-width: 1920.98px) {
    padding-top: 150px;
  }

  ${media.lg} {
    padding-top: 235px;
  }

  ${media.md} {
    padding-top: 200px;
  }

  ${media.sm} {
    padding-top: 160px;
    padding-bottom: 25px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid #eaeef2;
  padding-bottom: 40px;

  ${media.sm} {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const Departament = styled.h3`
  font-size: 32px;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 0px;
  margin-bottom: 0px;

  ${media.sm} {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

export const ItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${media.sm} {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ItemName = styled(Link)`
  font-size: ${fontSize.md};
  font-weight: 500;
  line-height: 1.7;
  text-decoration: none;
  color: ${color.text.primary};
  transition: ${transition.base};
  margin-bottom: 10px;

  &:hover {
    color: ${color.primary};
  }
`;

export const ItemLocation = styled.span`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${color.primary};
`;
