import React from 'react';

import { GlobeCanvas } from './canvas';
import { Description } from './careers-mission-globe.styles';

export const GlobeView = ({ image, description }) => (
  <div>
    <GlobeCanvas image={image} />
    <Description>{description}</Description>
  </div>
);
