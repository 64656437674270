import styled from 'styled-components';

export const Image = styled.img`
  position: absolute;
  bottom: 0.5px;
  left: 50%;
  z-index: 1;
  width: 100.5%;
  min-width: 850px;
  transform: translateX(-50%) translateY(100%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }
`;
