import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import {
  TextWrapper as ButtonTextWrapper,
  ArrowWrapper as ButtonArrowWrapper,
} from 'components/shared/button/button.styles';
import { Title } from 'components/shared/title';
import { rgba } from 'utils';

import { Stripes } from './stripes';

export const BottomStripes = styled(Stripes)`
  top: auto;
  bottom: 5px;
  transform: translateY(100%) translateX(-50%) rotate(180deg);
`;

export const CTAButton = styled(Button)`
  ${media.md} {
    align-self: flex-end;
  }

  ${ButtonTextWrapper} {
    background-color: rgba(255, 255, 255, 0.15);
    padding-right: 60px;

    ${media.xs} {
      padding-right: 30px;
    }
  }

  ${ButtonArrowWrapper} {
    background-color: ${rgba('#e9fcff', 0.2)};
  }
`;

export const CTAText = styled.p`
  line-height: 1.4;
  color: #fbfbfe;
  margin-top: 0;
  margin-right: 25px;
  margin-bottom: 0;

  ${media.md} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const CTA = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(358deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.35));
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding-top: 25px;
  padding-right: 35px;
  padding-bottom: 25px;
  padding-left: 60px;

  ${media.md} {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 35px;
  }

  ${media.sm} {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
`;

export const ItemDescription = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  white-space: pre-line;
  color: ${rgba(color.text.tertiary, 0.5)};
  margin-top: 0;
  margin-bottom: 0;

  ${media.xs} {
    white-space: initial;
  }
`;

export const ItemTitle = styled(Title)`
  font-size: 28px;
  white-space: pre-line;
  color: ${color.text.tertiary};

  ${media.sm} {
    font-size: ${fontSize.md};
  }

  strong {
    font-weight: 500;
    background-image: linear-gradient(112deg, #ffe3e1, #956fa8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Item = styled.div`
  margin-bottom: 30px;

  ${media.sm} {
    margin-bottom: 25px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ItemsSide = styled.div`
  flex-basis: 50%;
  padding-right: 50px;
  padding-left: 50px;

  ${media.md} {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.sm} {
    margin-bottom: 25px;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  margin-right: -50px;
  margin-left: -50px;
  margin-bottom: 80px;

  ${media.md} {
    display: block;
    margin-right: 0;
    margin-left: 0;
  }

  ${media.sm} {
    margin-bottom: 35px;
  }
`;

export const StyledTitle = styled(Title)`
  color: #e2c8ff;
  margin-bottom: 50px;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;
  background-color: #280267;
  padding-top: 60px;
  padding-bottom: 80px;

  ${media.sm} {
    padding-top: 30px;
    padding-bottom: 40px;
  }
`;
