import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const Image = styled.img`
  position: absolute;
  top: -100px;
  right: -25px;
  height: 1535px;

  ${media.md} {
    top: -250px;
  }

  ${media.sm} {
    top: -130px;
  }
`;
