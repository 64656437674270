import * as React from 'react';

import { Container } from 'components/shared/container';
import { Link } from 'components/shared/link';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  StyledTitle,
  Description,
  SectionsWrapper,
  Section,
  SectionTitle,
  SectionDescription,
  SectionItemsWrapper,
  SectionItem,
  SectionItemIconWrapper,
  SectionItemIcon,
  SectionItemTitle,
  SectionItemDescription,
  CTA,
  CTAText,
  CTAButton,
} from './careers-benefits.styles';
import Section1Icon1 from './icons/careers-benefits-block1-icon01.url.svg';
import Section1Icon2 from './icons/careers-benefits-block1-icon02.url.svg';
import Section1Icon3 from './icons/careers-benefits-block1-icon03.url.svg';
import Section1Icon4 from './icons/careers-benefits-block1-icon04.url.svg';
import Section1Icon5 from './icons/careers-benefits-block1-icon05.url.svg';
import Section1Icon6 from './icons/careers-benefits-block1-icon06.url.svg';
import Section1Icon7 from './icons/careers-benefits-block1-icon07.url.svg';
import Section1Icon8 from './icons/careers-benefits-block1-icon08.url.svg';
import Section1Icon9 from './icons/careers-benefits-block1-icon09.url.svg';
import Section1Icon10 from './icons/careers-benefits-block1-icon10.url.svg';
import Section1Icon11 from './icons/careers-benefits-block1-icon11.url.svg';
import Section1Icon12 from './icons/careers-benefits-block1-icon12.url.svg';
import Section2Icon1 from './icons/careers-benefits-block2-icon01.url.svg';
import Section2Icon2 from './icons/careers-benefits-block2-icon02.url.svg';
import Section2Icon3 from './icons/careers-benefits-block2-icon03.url.svg';
import { Stripes1 } from './stripes1';
import { Stripes2 } from './stripes2';

const section1ItemIcons = [
  Section1Icon1,
  Section1Icon2,
  Section1Icon3,
  Section1Icon4,
  Section1Icon5,
  Section1Icon6,
  Section1Icon7,
  Section1Icon8,
  Section1Icon9,
  Section1Icon10,
  Section1Icon11,
  Section1Icon12,
];

const section2ItemIcons = [Section2Icon1, Section2Icon2, Section2Icon3];

export const Benefits = (props) => {
  const {
    title,
    description,
    section1Title,
    section1Items,
    section2Title,
    section2Description,
    section2Items,
    cta,
  } = props;

  return (
    <Wrapper>
      <Container>
        <StyledTitle as="h2" size="xxl">
          {title}
        </StyledTitle>
        <Description
          dangerouslySetInnerHTML={{
            __html: getTextWithoutParagraph(description),
          }}
        />

        <SectionsWrapper>
          <Section>
            <SectionTitle as="h3">{section1Title}</SectionTitle>

            <SectionItemsWrapper>
              {section1Items.map(({ title, description }, index) => (
                <SectionItem key={title}>
                  <SectionItemIconWrapper>
                    <SectionItemIcon
                      src={section1ItemIcons[index]}
                      width={45}
                      height={70}
                      loading="lazy"
                      alt=""
                    />
                  </SectionItemIconWrapper>

                  <div>
                    <SectionItemTitle as="h4" size="sm">
                      {title}
                    </SectionItemTitle>
                    <SectionItemDescription
                      dangerouslySetInnerHTML={{
                        __html: getTextWithoutParagraph(description),
                      }}
                    />
                  </div>
                </SectionItem>
              ))}
            </SectionItemsWrapper>
          </Section>

          <Section>
            <SectionTitle as="h3">{section2Title}</SectionTitle>
            <SectionDescription
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(section2Description),
              }}
            />

            <SectionItemsWrapper>
              {section2Items.map(({ title, description }, index) => (
                <SectionItem key={title}>
                  <SectionItemIconWrapper>
                    <SectionItemIcon
                      src={section2ItemIcons[index]}
                      width={45}
                      height={70}
                      loading="lazy"
                      alt=""
                    />
                  </SectionItemIconWrapper>

                  <div>
                    <SectionItemTitle as="h4" size="sm">
                      {title}
                    </SectionItemTitle>
                    <SectionItemDescription>{description}</SectionItemDescription>
                  </div>
                </SectionItem>
              ))}
            </SectionItemsWrapper>
          </Section>
        </SectionsWrapper>

        <CTA>
          <CTAText>{cta.text}</CTAText>
          <CTAButton component={Link} to={cta.button.url}>
            {cta.button.text}
          </CTAButton>
        </CTA>
      </Container>

      <Stripes1 />
      <Stripes2 />
    </Wrapper>
  );
};
