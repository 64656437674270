import React, { useState, useEffect } from 'react';

import { JobsView } from './careers-jobs.view';

export const Jobs = ({ title }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(
      `https://boards-api.greenhouse.io/v1/boards/wegiftakavouchermarketlimited/jobs?content=true`
    )
      .then((response) => response.json())
      .then(({ jobs }) => {
        const jobsByDepartmentAsObject = {};

        jobs.forEach(({ title, location, departments, absolute_url }) => {
          departments.forEach(({ name }) => {
            if (!jobsByDepartmentAsObject[name]) jobsByDepartmentAsObject[name] = [];
            jobsByDepartmentAsObject[name].push({
              name: title,
              location: location.name,
              url: absolute_url,
            });
          });
        });

        const jobsByDepartmentAsArray = Object.keys(jobsByDepartmentAsObject).map((department) => ({
          name: department,
          items: jobsByDepartmentAsObject[department],
        }));

        setItems(jobsByDepartmentAsArray);
      })
      .catch((error) => console.log(error));
  }, []);

  return <JobsView title={title} items={items} />;
};
