import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const TrianglesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 1920px;
  height: 100%;
  transform: translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }

  ${media.sm} {
    max-width: 1550px;
  }
`;

export const PhotoInner = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

export const Photo = styled.div`
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 12px 34px -20px rgba(0, 0, 0, 0.5);

  &:nth-of-type(1) {
    top: 35px;
    left: -125px;
    width: 275px;

    ${media.md} {
      left: -110px;
      width: 180px;
    }

    ${media.sm} {
      top: 350px;
      left: -30px;
      width: 135px;
    }

    ${media.xs} {
      top: 450px;
    }
  }

  &:nth-of-type(2) {
    top: 430px;
    left: -110px;
    width: 160px;

    ${media.md} {
      top: 300px;
      width: 105px;
    }

    ${media.sm} {
      top: 550px;
      left: -40px;
      width: 80px;
    }

    ${media.xs} {
      top: 650px;
    }
  }

  &:nth-of-type(3) {
    top: 470px;
    left: 100px;
    width: 285px;

    ${media.md} {
      top: 330px;
      left: 30px;
      width: 190px;
    }

    ${media.sm} {
      top: 575px;
      left: 55px;
      width: 120px;
    }

    ${media.xs} {
      top: 675px;
    }
  }

  &:nth-of-type(4) {
    top: -10px;
    right: -150px;
    width: 285px;

    ${media.md} {
      right: -125px;
      width: 190px;
    }

    ${media.sm} {
      top: 325px;
      right: -30px;
      width: 120px;
    }

    ${media.xs} {
      top: 425px;
    }
  }

  &:nth-of-type(5) {
    top: 320px;
    right: -80px;
    width: 275px;

    ${media.md} {
      top: 230px;
      width: 180px;
    }

    ${media.sm} {
      top: 470px;
      right: -10px;
      width: 135px;
    }

    ${media.xs} {
      top: 570px;
    }
  }

  &:nth-of-type(6) {
    top: 580px;
    right: 115px;
    width: 260px;

    ${media.md} {
      top: 400px;
      right: 80px;
      width: 175px;
    }

    ${media.sm} {
      top: 600px;
      right: 10px;
      width: 120px;
    }

    ${media.xs} {
      top: 700px;
    }
  }
`;

export const PhotosWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
`;

export const Description = styled.p`
  max-width: 520px;
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.primary};
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;

  strong {
    font-weight: 500;
  }
`;

export const Subtitle = styled(Title)`
  margin-bottom: 30px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 10px;

  ${media.sm} {
    font-weight: 400;
    margin-bottom: 0;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 2;
`;

export const Wrapper = styled.section`
  position: relative;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 650px;

  ${media.md} {
    padding-bottom: 350px;
  }

  ${media.sm} {
    padding-top: 120px;
    padding-bottom: 550px;
  }
`;
