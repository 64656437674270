import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { CommitmentView } from './careers-commitment.view';

export const Commitment = (props) => {
  const {
    illustration: {
      childImageSharp: { fluid: illustrationFluid },
    },
  } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "pages/careers/commitment/illustration@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 351) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return <CommitmentView {...props} illustration={illustrationFluid} />;
};
