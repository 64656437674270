import * as React from 'react';

import { Container } from 'components/shared/container';
import { Link } from 'components/shared/link';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  StyledTitle,
  ItemsWrapper,
  ItemsSide,
  Item,
  ItemTitle,
  ItemDescription,
  CTA,
  CTAText,
  CTAButton,
  BottomStripes,
} from './careers-progress.styles';
import { Stripes } from './stripes';

export const Progress = (props) => {
  const { title, leftItems, rightItems, cta } = props;

  return (
    <Wrapper>
      <Container>
        <StyledTitle as="h2" size="xxl">
          {title}
        </StyledTitle>

        <ItemsWrapper>
          <ItemsSide>
            {leftItems.map(({ title, description }) => (
              <Item key={title}>
                <ItemTitle
                  as="h3"
                  dangerouslySetInnerHTML={{
                    __html: getTextWithoutParagraph(title),
                  }}
                />
                <ItemDescription>{description}</ItemDescription>
              </Item>
            ))}
          </ItemsSide>
          <ItemsSide>
            {rightItems.map(({ title, description }) => (
              <Item key={title}>
                <ItemTitle
                  as="h3"
                  dangerouslySetInnerHTML={{
                    __html: getTextWithoutParagraph(title),
                  }}
                />
                <ItemDescription>{description}</ItemDescription>
              </Item>
            ))}
          </ItemsSide>
        </ItemsWrapper>

        <CTA>
          <CTAText>{cta.text}</CTAText>
          <CTAButton component={Link} to={cta.button.url}>
            {cta.button.text}
          </CTAButton>
        </CTA>
      </Container>

      <Stripes />
      <BottomStripes />
    </Wrapper>
  );
};
