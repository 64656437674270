import styled, { css } from 'styled-components';

import { fontSize, color, transition, media } from 'components/_settings/variables';
import { Link } from 'components/shared/link';
import { Title } from 'components/shared/title';

import ExternalLinkIcon from './icons/careers-team-link-block-external-link.svg';

export const Description = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;

  ${media.sm} {
    font-size: ${fontSize.xs};
  }
`;

export const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-top: 2.5px;
`;

export const StyledTitle = styled(Title)`
  font-weight: 400;
  color: #7f7acf;
  margin-right: 15px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  ${media.sm} {
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

export const IconImage = styled.img`
  height: auto;
  width: 45px;
  ${media.sm} {
    width: 20px;
  }
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #f1f0ff;
  border-radius: 50%;
  margin-right: 30px;

  ${media.lg} {
    width: 95px;
    height: 95px;
    margin-right: 25px;
  }

  ${media.md} {
    width: 100px;
    height: 100px;
    margin-right: 30px;
  }

  ${media.sm} {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
`;

export const Wrapper = styled(Link)`
  flex-basis: 50%;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding-top: 40px;
  padding-right: 35px;
  padding-bottom: 40px;
  padding-left: 35px;
  margin-right: 30px;
  transition: ${transition.base};

  ${media.lg} {
    padding-top: 35px;
    padding-right: 30px;
    padding-bottom: 35px;
    padding-left: 30px;
  }

  ${media.md} {
    padding-top: 40px;
    padding-right: 35px;
    padding-bottom: 40px;
    padding-left: 35px;
    margin-right: 0;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.sm} {
    padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    margin-bottom: 15px;
  }

  &:hover {
    box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.6), 2px 2px 12px 0 rgba(26, 26, 67, 0.1);
  }

  &:last-child {
    margin-right: 0;
  }

  ${(p) => css`
    ${p.theme === 'orange' &&
    css`
      ${StyledTitle} {
        color: #e17a19;
      }

      ${IconWrapper} {
        background-color: #f8dec6;
      }

      ${StyledExternalLinkIcon} {
        g {
          stroke: #e17a19;
        }
      }
    `}
  `}
`;
