import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { getFluidImage } from 'utils';

import { GlobeView } from './careers-mission-globe.view';

export const Globe = ({ description }) => {
  const { globe } = useStaticQuery(graphql`
    query {
      globe: file(relativePath: { eq: "pages/careers/mission/careers-mission-globe.png" }) {
        childImageSharp {
          fluid(maxWidth: 540) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return <GlobeView image={getFluidImage(globe)} description={description} />;
};
