import styled, { css } from 'styled-components';

import { fontSize, color, transition, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const SwitcherDescription = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.5;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;
`;

export const SwitcherTitle = styled(Title)`
  margin-bottom: 5px;
`;

export const Switcher = styled.button`
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding-top: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  margin-bottom: 10px;
  margin-left: -25px;
  cursor: pointer;
  outline: none;
  transition: ${transition.base};

  ${media.lg} {
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 0;
  }

  &:hover {
    background-color: #ffffff;
    box-shadow: 0 7px 14px -10px rgba(0, 0, 0, 0.5);
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${(p) => css`
    ${p.active &&
    css`
      background-color: #ffffff;
      box-shadow: 0 7px 14px -10px rgba(0, 0, 0, 0.5);

      ${SwitcherTitle} {
        background-image: linear-gradient(to right, #a27ffc, #5457bf);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    `}
  `}
`;

export const SwitchersWrapper = styled.div`
  max-width: 560px;

  ${media.lg} {
    max-width: 480px;
  }

  ${media.md} {
    max-width: 100%;
  }
`;

export const Illustration = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  transition: ${transition.base};

  ${media.md} {
    position: static;
    display: none;
    max-width: 100%;
    height: 340px;
    margin-bottom: 20px;
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  ${(p) => css`
    ${p.visible &&
    css`
      opacity: 1;
      visibility: visible;

      ${media.md} {
        display: block;
      }
    `}
  `}
`;

export const Wrapper = styled.div`
  position: relative;
`;
