import styled from 'styled-components';

export const Image = styled.img`
  position: absolute;
  top: 5px;
  left: 50%;
  width: 100.5%;
  min-width: 1200px;
  transform: translateY(-100%) translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }
`;
