import * as React from 'react';
import Swiper from 'react-id-swiper';

import { Container } from 'components/shared/container';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  StyledTitle,
  Description,
  MembersWrapper,
  MembersSliderWrapper,
  LinkBlocksWrapper,
} from './careers-team.styles';
import LinkBlockIconSvgUrl1 from './icons/careers-team-link-block1-icon.url.svg';
import LinkBlockIconSvgUrl2 from './icons/careers-team-link-block2-icon.url.svg';
import { LinkBlock } from './link-block';
import { Member } from './member';

import 'swiper/swiper.min.css';

export const Team = ({ title, description, members, linkBlock1, linkBlock2 }) => (
  <Wrapper>
    <Container>
      <StyledTitle
        as="h2"
        size="xxl"
        dangerouslySetInnerHTML={{
          __html: getTextWithoutParagraph(title),
        }}
      />

      <Description>{description}</Description>

      <MembersWrapper>
        {members.map(({ photo, name, role, text }) => (
          <Member photo={photo} name={name} role={role} text={text} key={name} />
        ))}
      </MembersWrapper>

      <MembersSliderWrapper>
        <Swiper autoHeight>
          {members.map(({ photo, name, role, text }) => (
            <div key={name}>
              <Member photo={photo} name={name} role={role} text={text} />
            </div>
          ))}
        </Swiper>
      </MembersSliderWrapper>

      <LinkBlocksWrapper>
        <LinkBlock
          title={linkBlock1.title}
          description={linkBlock1.description}
          url={linkBlock1.url}
          icon={LinkBlockIconSvgUrl1}
        />
        <LinkBlock
          title={linkBlock2.title}
          description={linkBlock2.description}
          url={linkBlock2.url}
          icon={LinkBlockIconSvgUrl2}
          theme="orange"
        />
      </LinkBlocksWrapper>
    </Container>
  </Wrapper>
);
