import * as React from 'react';

import {
  Wrapper,
  Container,
  ContentWrapper,
  ContentLeft,
  ContentRight,
  Illustration,
  Title,
  Description,
} from './careers-commitment.styles';

export const CommitmentView = ({ title, description, illustration }) => (
  <Wrapper>
    <Container>
      <ContentWrapper>
        <ContentLeft id="digital-infrastructure">
          <Title>{title}</Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </ContentLeft>
        <ContentRight>
          <Illustration fluid={illustration} />
        </ContentRight>
      </ContentWrapper>
    </Container>
  </Wrapper>
);
