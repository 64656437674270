import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';

export const Text = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;
`;

export const Role = styled.span`
  display: block;
  font-size: ${fontSize.sm};
  font-weight: 500;
  line-height: 1.5;
  color: #798fd6;
  margin-bottom: 20px;
`;

export const Photo = styled(GatsbyImage)`
  max-width: 100%;
  margin-bottom: 30px;

  ${media.md} {
    max-width: 75%;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Wrapper = styled.div`
  flex-basis: 32.5%;
  padding-right: 30px;
  padding-left: 30px;

  ${media.lg} {
    padding-right: 20px;
    padding-left: 20px;
  }

  ${media.md} {
    max-width: 400px;
    text-align: center;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }

  &:nth-of-type(2) {
    flex-basis: 35%;
    margin-top: 45px;

    ${media.md} {
      margin-top: 0;
    }
  }
`;
