import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { StripesView } from './careers-progress-stripes.view';

export const Stripes = ({ className }) => {
  const { stripes } = useStaticQuery(graphql`
    query {
      stripes: file(relativePath: { eq: "pages/careers/progress/careers-progress-stripes.svg" }) {
        publicURL
      }
    }
  `);

  return <StripesView className={className} imageSrc={stripes.publicURL} />;
};
