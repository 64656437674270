import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const Image = styled.img`
  position: absolute;
  top: -270px;
  left: -175px;
  height: 1880px;

  ${media.sm} {
    top: -420px;
  }

  ${media.md} {
    top: -300px;
  }
`;
