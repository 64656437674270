import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { Button } from 'components/shared/button';
import { getFluidImage, getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  StyledTitle,
  Subtitle,
  Description,
  PhotosWrapper,
  Photo,
  PhotoInner,
  TrianglesWrapper,
} from './careers-intro.styles';
import { Triangle1 } from './triangle1';
import { Triangle2 } from './triangle2';

export const Intro = (props) => {
  const { title, subtitle, description, buttonText, photos } = props;

  return (
    <Wrapper>
      <Inner>
        <StyledTitle
          as="h1"
          size="sm"
          dangerouslySetInnerHTML={{
            __html: getTextWithoutParagraph(title),
          }}
        />

        <Subtitle as="h2" size="xxl">
          {subtitle}
        </Subtitle>
        <Description
          dangerouslySetInnerHTML={{
            __html: getTextWithoutParagraph(description),
          }}
        />
        <Button href="#jobs" theme="light" as="a">
          {buttonText}
        </Button>

        <PhotosWrapper>
          {photos.map(({ id, altText, localFile }) => (
            <Photo key={id}>
              <PhotoInner>
                <GatsbyImage fluid={getFluidImage(localFile)} alt={altText} loading="eager" />
              </PhotoInner>
            </Photo>
          ))}
        </PhotosWrapper>
      </Inner>

      <TrianglesWrapper>
        <Triangle1 />
        <Triangle2 />
      </TrianglesWrapper>
    </Wrapper>
  );
};
