import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Benefits } from 'components/pages/careers/benefits';
import { Intro } from 'components/pages/careers/intro';
import { Jobs } from 'components/pages/careers/jobs';
import { Mission } from 'components/pages/careers/mission';
import { Progress } from 'components/pages/careers/progress';
import { SolutionsBanner } from 'components/pages/careers/solutions-banner';
import { Team } from 'components/pages/careers/team';
import { Values } from 'components/pages/careers/values';

import { Commitment } from '../components/pages/careers/commitment';
import { buildPageMetadata } from '../utils/data-builders';
import { extractItems } from '../utils/extract-items';

const Careers = ({
  data: {
    wpPage,
    wpPage: { acf },
  },
}) => {
  const {
    section1Title,
    section1Subtitle,
    section1Description,
    section1ButtonText,
    section1Photos,

    section2Title,
    section2Description,
    section2TextUnderImage,

    section3Title,
    section3CtaText,
    section3CtaButtonText,
    section3CtaButtonUrl,

    section4Title,
    section4LeftDescription,
    section4RightDescription,

    section5Title,
    section5Description,
    section5BottomBlock1Title,
    section5BottomBlock1Description,
    section5BottomBlock1Url,
    section5BottomBlock2Title,
    section5BottomBlock2Description,
    section5BottomBlock2Url,

    section6Title,
    section6Description,
    section6Block1Title,
    section6Block2Title,
    section6Block2Description,
    section6CtaText,
    section6CtaButtonText,
    section6CtaButtonUrl,

    section7Title,

    commitment,

    banner,
  } = acf;

  const progressItems = extractItems(acf, 'section3Feature');

  const progressLeftItems = progressItems.slice(0, 2);
  const progressRightItems = progressItems.slice(2, 4);

  const progressCTA = {
    text: section3CtaText,
    button: {
      text: section3CtaButtonText,
      url: section3CtaButtonUrl,
    },
  };

  const valuesDescriptions = [section4LeftDescription, section4RightDescription];

  const valuesTabs = extractItems(acf, 'section4Tab');
  const teamMembers = extractItems(acf, 'section5Member');

  const teamLinkBlock1 = {
    title: section5BottomBlock1Title,
    description: section5BottomBlock1Description,
    url: section5BottomBlock1Url,
  };

  const teamLinkBlock2 = {
    title: section5BottomBlock2Title,
    description: section5BottomBlock2Description,
    url: section5BottomBlock2Url,
  };

  const benefitsSection1Items = extractItems(acf, 'section6Block1Feature');
  const benefitsSection2Items = extractItems(acf, 'section6Block2Feature');

  const benefitsCTA = {
    text: section6CtaText,
    button: {
      text: section6CtaButtonText,
      url: section6CtaButtonUrl,
    },
  };

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Helmet>
        <script src="https://www.workable.com/assets/embed.js" type="text/javascript" async />
      </Helmet>
      <Header />
      <Intro
        title={section1Title}
        subtitle={section1Subtitle}
        description={section1Description}
        buttonText={section1ButtonText}
        photos={section1Photos}
      />
      <Mission
        title={section2Title}
        description={section2Description}
        globeDescription={section2TextUnderImage}
      />
      <Progress
        title={section3Title}
        leftItems={progressLeftItems}
        rightItems={progressRightItems}
        cta={progressCTA}
      />
      <Values title={section4Title} descriptions={valuesDescriptions} tabs={valuesTabs} />
      <Team
        title={section5Title}
        description={section5Description}
        members={teamMembers}
        linkBlock1={teamLinkBlock1}
        linkBlock2={teamLinkBlock2}
      />
      <Commitment title={commitment.title} description={commitment.description} />
      <SolutionsBanner
        title={banner.title}
        buttonText={banner.buttonText}
        buttonLink={banner.buttonLink}
      />
      <Benefits
        title={section6Title}
        description={section6Description}
        section1Title={section6Block1Title}
        section1Items={benefitsSection1Items}
        section2Title={section6Block2Title}
        section2Description={section6Block2Description}
        section2Items={benefitsSection2Items}
        cta={benefitsCTA}
      />
      <Jobs title={section7Title} />
      <Footer theme="light" />
    </Layout>
  );
};

export default Careers;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        section1Title
        section1Subtitle
        section1Description
        section1ButtonText
        section1Photos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        section2Title
        section2Description
        section2TextUnderImage

        section3Title
        section3Feature1Title
        section3Feature1Description
        section3Feature2Title
        section3Feature2Description
        section3Feature3Title
        section3Feature3Description
        section3Feature4Title
        section3Feature4Description
        section3CtaText
        section3CtaButtonText
        section3CtaButtonUrl
        section4Title
        section4LeftDescription
        section4RightDescription
        section4Tab1Title
        section4Tab1Description
        section4Tab2Title
        section4Tab2Description
        section4Tab3Title
        section4Tab3Description
        section4Tab4Title
        section4Tab4Description

        section5Title
        section5Description
        section5Member1Photo {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section5Member1Name
        section5Member1Role
        section5Member1Text
        section5Member2Photo {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section5Member2Name
        section5Member2Role
        section5Member2Text
        section5Member3Photo {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section5Member3Name
        section5Member3Role
        section5Member3Text
        section5BottomBlock1Title
        section5BottomBlock1Description
        section5BottomBlock1Url
        section5BottomBlock2Title
        section5BottomBlock2Description
        section5BottomBlock2Url

        section6Title
        section6Description
        section6Block1Title
        section6Block1Feature1Title
        section6Block1Feature1Description
        section6Block1Feature2Title
        section6Block1Feature2Description
        section6Block1Feature3Title
        section6Block1Feature3Description
        section6Block1Feature4Title
        section6Block1Feature4Description
        section6Block1Feature5Title
        section6Block1Feature5Description
        section6Block1Feature6Title
        section6Block1Feature6Description
        section6Block1Feature7Title
        section6Block1Feature7Description
        section6Block1Feature8Title
        section6Block1Feature8Description
        section6Block1Feature9Title
        section6Block1Feature9Description
        section6Block1Feature10Title
        section6Block1Feature10Description
        section6Block1Feature11Title
        section6Block1Feature11Description
        section6Block1Feature12Title
        section6Block1Feature12Description
        section6Block2Title
        section6Block2Description
        section6Block2Feature1Title
        section6Block2Feature1Description
        section6Block2Feature2Title
        section6Block2Feature2Description
        section6Block2Feature3Title
        section6Block2Feature3Description
        section6CtaText
        section6CtaButtonText
        section6CtaButtonUrl

        section7Title

        commitment {
          title
          description
        }

        banner {
          title
          buttonText
          buttonLink
        }
      }
    }
  }
`;
