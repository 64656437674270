import styled from 'styled-components';

import { media, color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import {
  TextWrapper as ButtonTextWrapper,
  ArrowWrapper as ButtonArrowWrapper,
} from 'components/shared/button/button.styles';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  margin-bottom: 365px;

  ${media.lg} {
    margin-bottom: 235px;
  }

  ${media.md} {
    margin-bottom: 150px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 35px 30px 55px;
  border-radius: 8px;
  box-shadow: 2px 2px 12px rgba(26, 26, 67, 0.05), 14px 36px 150px -45px rgba(50, 50, 93, 0.5);
  background-color: ${color.text.tertiary};
  ${media.md} {
    align-items: initial;
    flex-direction: column;

    padding-left: 35px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  ${media.md} {
    align-items: initial;
    flex-direction: column;
  }
`;

export const Text = styled.div`
  ${media.lg} {
    max-width: 396px;
  }

  ${media.md} {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

export const TitleStyled = styled(Title)`
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;

  ${media.sm} {
    font-size: 16px;
  }
`;

export const CTAButton = styled(Button)`
  ${media.md} {
    align-self: flex-end;
  }

  ${ButtonTextWrapper} {
    padding-right: 110px;

    ${media.sm} {
      padding-right: 30px;
    }
  }

  ${ButtonArrowWrapper} {
    width: 64px;
  }
`;
