import * as React from 'react';

import { Title } from 'components/shared/title';
import { getFluidImage } from 'utils';

import { Wrapper, Photo, Role, Text } from './careers-team-member.styles';

export const Member = ({ className, photo, name, role, text }) => (
  <Wrapper className={className}>
    <Photo fluid={getFluidImage(photo.localFile)} alt={photo.altText} />
    <Title as="h3" size="sm">
      {name}
    </Title>
    <Role>{role}</Role>
    <Text>{text}</Text>
  </Wrapper>
);
