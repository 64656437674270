import React, { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { Image } from './careers-mission-globe-canvas.styles';
import { GlobeCanvasView } from './careers-mission-globe-canvas.view';
import globePointsData from './globe-point.json';

/* VARIABLES SETUP */

// Map properties for creation and rendering
const mapProps = {
  mapSize: {
    // Size of the map from the intial source image (on which the dots are positioned on)
    width: 2048 / 2,
    height: 1024 / 2,
  },
  globeRadius: 200, // Radius of the globe (used for many calculations)

  colours: {
    // Cache the colours
    globeDots: 'rgb(189, 186, 231)',
  },
  alphas: {
    // Transparent values of materials
    globe: 1,
  },
};

/* INTRO ANIMATIONS */

// Easing reference: https://gist.github.com/gre/1650294

const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
const easeOutCubic = (t) => --t * t * t + 1;

/* HELPER FNS SETUP */

/* COORDINATE CALCULATIONS */

// Returns an object of 3D spherical coordinates
const returnSphericalCoordinates = (latitude, longitude) => {
  /*
            This function will take a latitude and longitude and calcualte the
            projected 3D coordiantes using Mercator projection relative to the
            radius of the globe.
            Reference: https://stackoverflow.com/a/12734509
        */

  // Convert latitude and longitude on the 90/180 degree axis
  latitude = ((latitude - mapProps.mapSize.width) / mapProps.mapSize.width) * -180;
  longitude = ((longitude - mapProps.mapSize.height) / mapProps.mapSize.height) * -90;

  // Calculate the projected starting point
  const radius = Math.cos((longitude / 180) * Math.PI) * mapProps.globeRadius;
  const x = Math.cos((latitude / 180) * Math.PI) * radius;
  const y = Math.sin((longitude / 180) * Math.PI) * mapProps.globeRadius;
  const z = Math.sin((latitude / 180) * Math.PI) * radius;

  return { x, y, z };
};

/* ACTUAL COMPONENT */

export default function GlobeCanvas({ image }) {
  // Boolean to enable or disable rendering when window is in or out of focus
  const [isHidden, setIsHidden] = useState(false);

  // auxillary state
  const [isBrowser, setIsBrowser] = useState(false);

  // Container canvas
  const globeImgWrapper = useRef(null);
  const globeCanvas = useRef(null);

  // Scroll to block for animate-in globe
  const [globeImg, globeImgInView] = useInView({
    threshold: [0.25, 0.5, 0.75],
  });

  // animate variables
  const clock = new THREE.Clock();
  let time = 0;

  // Three group objects
  const groups = {
    main: null, // A group containing everything
    globe: null, // A group containing the globe sphere (and globe dots)
    globeDots: null, // A group containing the globe dots
    globeCards: null,
  };

  // Angles used for animating the camera
  const camera = {
    object: null, // Three object of the camera
    controls: null, // Three object of the orbital controls
    angles: {
      // Object of the camera angles for animating
      current: {
        azimuthal: null,
        polar: null,
      },
      target: {
        azimuthal: null,
        polar: null,
      },
    },
  };

  // Booleans and values for animations
  const animations = {
    dots: {
      current: 0, // Animation frames of the globe dots introduction animation
      total: 170, // Total frames (duration) of the globe dots introduction animation,
      points: [], // Array to clone the globe dots coordinates to
    },
    globe: {
      current: 1, // Animation frames of the globe introduction animation
      total: 80, // Total frames (duration) of the globe introduction animation,
    },
  };

  /* SETUP */
  const setupScene = (data) => {
    // Cache DOM selectors
    const container = globeImgWrapper.current;
    const canvas = globeCanvas.current;
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true,
      shadowMapEnabled: false,
    });

    renderer.setSize(canvas.clientWidth, canvas.clientHeight);
    renderer.setPixelRatio(1);
    renderer.setClearColor(0x000000, 0);

    // Main group that contains everything
    groups.main = new THREE.Group();
    groups.main.name = 'Main';

    // Add the main group to the scene
    scene.add(groups.main);

    // Render camera and add orbital controls
    addCamera(canvas);
    addControls(canvas);

    // Render objects
    const globeElement = addGlobe(data);
    // Start the requestAnimationFrame loop
    renderer.render(scene, camera.object);
    animate(renderer, scene, globeElement)();

    const canvasResizeBehaviour = () => {
      container.width = container.clientWidth;
      container.height = container.clientHeight;

      camera.object.aspect = container.width / container.height;
      camera.object.updateProjectionMatrix();
      renderer.setSize(container.width, container.height);
    };

    window.addEventListener('resize', canvasResizeBehaviour);
    window.addEventListener('orientationchange', canvasResizeBehaviour);

    canvasResizeBehaviour();
  };

  /* CAMERA AND CONTROLS */
  const addCamera = (canvas) => {
    const { clientWidth, clientHeight } = canvas;
    camera.object = new THREE.PerspectiveCamera(60, clientWidth / clientHeight, 1, 10000);
    camera.object.position.z = mapProps.globeRadius * 2.2;
  };

  const addControls = (canvas) => {
    camera.controls = new OrbitControls(camera.object, canvas);
    camera.controls.enableKeys = false;
    camera.controls.enablePan = false;
    camera.controls.enableZoom = false;
    camera.controls.enableDamping = false;
    camera.controls.enableRotate = false;

    // Set the initial camera angles to something crazy for the introduction animation
    camera.angles.current.azimuthal = -Math.PI;
    camera.angles.current.polar = 0;
  };

  /* RENDERING */

  const animate = (renderer, scene, globeElement) => () => {
    if (!isHidden) {
      requestAnimationFrame(animate(renderer, scene, globeElement));
      time += clock.getDelta();

      groups.globeDots.rotation.y = -time * 0.05;

      setIsHidden(!isHidden);
    }

    if (groups.globeDots) {
      introAnimate(globeElement);
    }

    camera.controls.update();
    renderer.render(scene, camera.object);
  };

  /* GLOBE */

  const addGlobe = (data) => {
    const textureLoader = new THREE.TextureLoader();
    textureLoader.setCrossOrigin(true);

    const radius = mapProps.globeRadius - mapProps.globeRadius * 0.02;

    const segments = 64;
    const rings = 64;

    // Make gradient
    const canvasSize = 128;
    const textureCanvas = document.createElement('canvas');
    textureCanvas.width = canvasSize;
    textureCanvas.height = canvasSize;
    const canvasContext = textureCanvas.getContext('2d');
    canvasContext.rect(0, 0, canvasSize, canvasSize);
    const canvasGradient = canvasContext.createLinearGradient(0, 0, 0, canvasSize);
    canvasGradient.addColorStop(0, '#7e79cf');
    canvasGradient.addColorStop(0.5, '#7e79cf');
    canvasGradient.addColorStop(1, '#7e79cf');
    canvasContext.fillStyle = canvasGradient;
    canvasContext.fill();

    // Make texture
    const texture = new THREE.Texture(textureCanvas);
    texture.needsUpdate = true;

    const geometry = new THREE.SphereGeometry(radius, segments, rings);
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: 0,
    });

    const globeElement = new THREE.Mesh(geometry, material);

    groups.globe = new THREE.Group();
    groups.globe.name = 'Globe';
    groups.globe.add(globeElement);
    groups.main.add(groups.globe);
    addGlobeDots(data);
    return globeElement;
  };

  function addGlobeDots(data) {
    const geometry = new THREE.Geometry();
    // Make circle
    const canvasSize = 16;
    const halfSize = canvasSize / 2;
    const textureCanvas = document.createElement('canvas');
    textureCanvas.width = canvasSize;
    textureCanvas.height = canvasSize;
    const canvasContext = textureCanvas.getContext('2d');
    canvasContext.beginPath();
    canvasContext.arc(halfSize, halfSize, halfSize, 0, 2 * Math.PI);
    canvasContext.fillStyle = mapProps.colours.globeDots;
    canvasContext.fill();

    // Make texture
    const texture = new THREE.Texture(textureCanvas);
    texture.needsUpdate = true;

    const material = new THREE.PointsMaterial({
      map: texture,
      size: 4,
    });

    function addDot({ x, y }) {
      // Add a point with zero coordinates
      const point = new THREE.Vector3(0, 0, 0);
      geometry.vertices.push(point);

      // Add the coordinates to a new array for the intro animation
      const result = returnSphericalCoordinates(x, y);
      animations.dots.points.push(new THREE.Vector3(result.x, result.y, result.z));
    }

    for (let i = 0; i < data.points.length; i++) {
      addDot(data.points[i]);
    }

    // Add the points to the scene
    groups.globeDots = new THREE.Points(geometry, material);
    groups.globe.add(groups.globeDots);
  }

  function introAnimate(globeElement) {
    const { dots, globe } = animations;

    if (dots.current <= dots.total) {
      const points = groups.globeDots.geometry.vertices;
      const totalLength = points.length;

      for (let i = 0; i < totalLength; i++) {
        // Get ease value and add delay based on loop iteration
        let dotProgress = easeInOutCubic(dots.current / dots.total);
        dotProgress += dotProgress * (i / totalLength);

        if (dotProgress > 1) {
          dotProgress = 1;
        }

        // Move the point
        points[i].x = dots.points[i].x * dotProgress;
        points[i].y = dots.points[i].y * dotProgress;
        points[i].z = dots.points[i].z * dotProgress;

        // Animate the camera at the same rate as the first dot
        if (i === 0) {
          const { current, target } = camera.angles;

          const azimuthalDifference = (current.azimuthal - target.azimuthal) * dotProgress;

          camera.angles.current.azimuthal = current.azimuthal - azimuthalDifference;
          const polarDifference = (current.polar - target.polar) * dotProgress;
          camera.angles.current.polar = current.polar - polarDifference;
        }
      }

      dots.current++;

      // Update verticies
      groups.globeDots.geometry.verticesNeedUpdate = true;
    }

    if (dots.current >= dots.total * 0.65 && globe.current <= globe.total) {
      const globeProgress = easeOutCubic(globe.current / globe.total);
      globeElement.material.opacity = mapProps.alphas.globe * globeProgress;
    }
  }

  useEffect(() => {
    if (
      !isBrowser &&
      window.WebGLRenderingContext &&
      typeof window.IntersectionObserver !== `undefined`
    )
      setIsBrowser(true);
    if (globeImgWrapper.current) {
      setupScene(globePointsData);
    }
  }, [isBrowser && globeImgInView]);

  return isBrowser ? (
    <GlobeCanvasView refWrapper={globeImgWrapper} refImg={globeImg} refCanvas={globeCanvas} />
  ) : (
    <Image fluid={image} />
  );
}
