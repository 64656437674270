import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import {
  TextWrapper as ButtonTextWrapper,
  ArrowWrapper as ButtonArrowWrapper,
} from 'components/shared/button/button.styles';
import { Title } from 'components/shared/title';
import { rgba } from 'utils';

export const CTAButton = styled(Button)`
  ${media.md} {
    align-self: flex-end;
  }

  ${ButtonTextWrapper} {
    background-color: rgba(255, 255, 255, 0.15);
    padding-right: 60px;

    ${media.xs} {
      padding-right: 30px;
    }
  }

  ${ButtonArrowWrapper} {
    background-color: ${rgba('#e9fcff', 0.2)};
  }
`;

export const CTAText = styled.p`
  line-height: 1.4;
  color: #fbfbfe;
  margin-top: 0;
  margin-right: 25px;
  margin-bottom: 0;

  ${media.md} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const CTA = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(358deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.35));
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding-top: 25px;
  padding-right: 35px;
  padding-bottom: 25px;
  padding-left: 60px;

  ${media.md} {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 35px;
  }

  ${media.sm} {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
`;

export const SectionItemDescription = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${rgba(color.text.tertiary, 0.6)};
  margin-top: 0;
  margin-bottom: 0;
`;

export const SectionItemTitle = styled(Title)`
  color: #e2c3b2;
  margin-bottom: 10px;

  ${media.sm} {
    margin-bottom: 2.5px;
  }
`;

export const SectionItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-image: linear-gradient(135deg, rgba(224, 193, 179, 0), rgba(255, 238, 231, 0.33));
  border-radius: 8px;
  margin-bottom: 30px;

  ${media.sm} {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-right: 25px;
    margin-bottom: 0;
  }
`;

export const SectionItemIcon = styled.img`
  height: auto;
  width: 45px;
  ${media.sm} {
    width: 25px;
  }
`;

export const SectionItem = styled.div`
  flex-basis: 33.333%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 60px;

  ${media.md} {
    flex-basis: 50%;
  }

  ${media.sm} {
    display: flex;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const SectionItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-bottom: -60px;
  margin-left: -15px;

  ${media.sm} {
    display: block;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
`;

export const SectionDescription = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: #e2c8ff;
  margin-top: -30px;
  margin-bottom: 50px;

  ${media.sm} {
    margin-top: -15px;
    margin-bottom: 15px;
  }
`;

export const SectionTitle = styled(Title)`
  background-image: linear-gradient(94deg, #7d71bc, #ffdab0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;

  ${media.sm} {
    margin-bottom: 25px;
  }
`;

export const Section = styled.div`
  margin-bottom: 35px;

  ${media.sm} {
    margin-bottom: 90px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionsWrapper = styled.div`
  margin-bottom: 95px;

  ${media.sm} {
    margin-bottom: 70px;
  }
`;

export const Description = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: #e2c8ff;
  margin-top: 0;
  margin-bottom: 70px;

  ${media.sm} {
    margin-bottom: 50px;
  }
`;

export const StyledTitle = styled(Title)`
  background-image: linear-gradient(98deg, #7d71bc, #ffdab0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background-image: linear-gradient(0deg, #131041, #2c295d);
  padding-top: 120px;
  padding-bottom: 60px;

  @media only screen and (min-width: 1920.98px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${media.sm} {
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;
