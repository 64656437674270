import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const LinkBlocksWrapper = styled.div`
  display: flex;

  ${media.md} {
    display: block;
  }
`;

export const MembersSliderWrapper = styled.div`
  display: none;
  margin-bottom: 100px;

  ${media.md} {
    display: block;
  }

  ${media.sm} {
    margin-bottom: 25px;
  }
`;

export const MembersWrapper = styled.div`
  display: flex;
  margin-right: -30px;
  margin-bottom: 100px;
  margin-left: -30px;

  ${media.lg} {
    margin-right: -20px;
    margin-left: -20px;
  }

  ${media.md} {
    display: none;
  }
`;

export const Description = styled.p`
  max-width: 1100px;
  font-size: ${fontSize.sm};
  line-height: 1.75;
  text-align: center;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;

  ${media.sm} {
    margin-bottom: 65px;
  }
`;

export const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 15px;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 220px;
  padding-bottom: 185px;

  @media only screen and (min-width: 1920.98px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${media.sm} {
    padding-top: 120px;
    padding-bottom: 80px;
  }
`;
