import * as React from 'react';

import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  StyledTitle,
  List,
  ListItem,
  Item,
  Departament,
  ItemsWrapper,
  ItemName,
  ItemLocation,
} from './careers-jobs.styles';

export const JobsView = ({ title, items }) => (
  <Wrapper>
    <Inner id="jobs">
      <StyledTitle
        as="h2"
        size="lg"
        dangerouslySetInnerHTML={{
          __html: getTextWithoutParagraph(title),
        }}
      />

      <List>
        {items.length
          ? items.map(({ name, items }, index) => (
              <ListItem key={index}>
                <Departament>{name}</Departament>
                <ItemsWrapper>
                  {items.map(({ name, url, location }, index) => (
                    <Item key={index}>
                      <ItemName to={url}>{name}</ItemName>
                      {location && <ItemLocation>{location}</ItemLocation>}
                    </Item>
                  ))}
                </ItemsWrapper>
              </ListItem>
            ))
          : 'There are no current openings.'}
      </List>
    </Inner>
  </Wrapper>
);
