import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  max-width: 540px;
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;

  ${media.sm} {
    max-width: 100%;
    margin-bottom: 50px;
  }

  strong {
    font-weight: 500;
    border-bottom: 6px solid #defbff;
  }
`;

export const StyledTitle = styled(Title)`
  max-width: 600px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;

  ${media.sm} {
    max-width: 100%;
    margin-bottom: 30px;
  }
`;

export const Wrapper = styled.section`
  text-align: center;
  padding-bottom: 350px;

  ${media.lg} {
    padding-bottom: 235px;
  }

  ${media.sm} {
    padding-bottom: 180px;
  }
`;
