import { Link } from 'gatsby';
import React from 'react';

import {
  Wrapper,
  Inner,
  ContentWrapper,
  Left,
  TitleStyled,
  Text,
  CTAButton,
} from './solutions-banner.styles';

export const SolutionsBanner = ({ title, buttonText, buttonLink }) => (
  <Wrapper>
    <Inner>
      <ContentWrapper>
        <Left>
          <Text>
            <TitleStyled as="h3">{title}</TitleStyled>
          </Text>
        </Left>
        <CTAButton component={Link} theme="low-light" to={buttonLink}>
          {buttonText}
        </CTAButton>
      </ContentWrapper>
    </Inner>
  </Wrapper>
);
