import React from 'react';

import { ImageWrapper, CardsWrapper, CardsItem } from './careers-mission-globe-canvas.styles';

export const GlobeCanvasView = ({ refWrapper, refImg, refCanvas }) => (
  <ImageWrapper ref={refWrapper}>
    <CardsWrapper>
      <CardsItem />
      <CardsItem />
      <CardsItem />
      <CardsItem />
    </CardsWrapper>
    <div className="globe-img" ref={refImg}>
      <canvas className="globe-img-canvas" ref={refCanvas} />
    </div>
  </ImageWrapper>
);
