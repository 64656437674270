import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';

export const Description = styled.p`
  position: relative;
  z-index: 1;
  max-width: 580px;
  font-size: ${fontSize.lg};
  text-align: center;
  line-height: 1.7;
  color: ${color.text.secondary};
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding-top: 25px;
  padding-right: 40px;
  padding-bottom: 25px;
  padding-left: 40px;
  margin-right: auto;
  margin-top: -125px;
  margin-left: auto;
  margin-bottom: 0;

  ${media.sm} {
    font-size: ${fontSize.md};
    line-height: 1.4;
    margin-top: 0;
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
  }
`;
